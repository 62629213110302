import axios from 'axios';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import DescriptionList from './components/DescriptionList';
import { UserGroupIcon, UserIcon } from '@heroicons/react/24/outline';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@tremor/react';
import { useState } from 'react';
// import Pagination from '../../ui/component/pagination';
import usePagination from '../../hooks/usePagination';

const profile = {
  coverImageUrl:
    'https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80',
};

enum EProfileTab {
  Profile = 'PROFILE',
  HolidayRequests = 'HOLIDAY_REQUESTS',
}

const tabs = [
  { name: 'Profile', href: '#', current: true },
  { name: 'Holiday Requests', href: '#', current: false },
];

const { REACT_APP_API_HOST } = process.env;

export function ProfileRoute() {
  const { id } = useParams();

  const { isLoading, isFetched, error, data } = useQuery({
    queryKey: ['home-section', id],
    queryFn: async (options) => {
      const { queryKey } = options;
      const [key, id] = queryKey;
      let data;

      if (typeof id !== 'undefined') {
        const { data: response } = await axios({
          method: 'get',
          url: `${REACT_APP_API_HOST}/v1/people/${id}`,
        });

        data = response;
      } else {
        const { data: response } = await axios({
          method: 'get',
          url: `${REACT_APP_API_HOST}/v1/profile`,
        });

        data = response;
      }
      return data;
    },
  });

  const ProfileDescription = () => {
    return (
      <>
        <DescriptionList payload={basicInformationPayload}></DescriptionList>
        <DescriptionList payload={contactInformationPayload}></DescriptionList>
        <DescriptionList
          payload={employmentInformationPayload}
        ></DescriptionList>
        <DescriptionList payload={accountInformationPayload}></DescriptionList>
        <DescriptionList
          payload={managementInformationPayload}
        ></DescriptionList>
      </>
    );
  };

  const queryHolidayRequest = async (
    page: number,
    count: number,
    params?: any,
  ) => {
    const { id } = params;

    if (typeof id !== 'undefined') {
      const { data } = await axios({
        method: 'get',
        url: `${REACT_APP_API_HOST}/v1/timeaway/requests/profile/${id}`,
        params: { page, count },
      });
      return data;
    } else {
      const { data } = await axios({
        method: 'get',
        url: `${REACT_APP_API_HOST}/v1/timeaway/requests`,
        params: { page, count },
      });
      return data;
    }
  };

  const ProfileHolidayRequest = () => {
    const [user, setUser] = useState<any>();
    const [token, setToken] = useState<string>();
    const [profile, setProfile] = useState<any>();

    const { id } = useParams();

    const {
      status,
      data: response = [],
      page,
      totalPages,
      totalItems,
      incrementPage,
      decrementPage,
      setDirectPage,
    } = usePagination({
      key: 'profile-section',
      fetcher: queryHolidayRequest,
      params: { id },
      initialPage: 0,
      initialItemsPerPage: 10,
    });

    const renderDecision = (decision: string) => {
      switch (decision) {
        case 'pending':
          return 'Pending';

        case 'approved':
          return 'Approved';

        default:
          return 'Unknown';
      }
    };

    return (
      <div className="overflow-hidden rounded-lg bg-white shadow">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="m4-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                      >
                        Type
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Details
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        From
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Until
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                      >
                        <span className="sr-only">View</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {response.map((item: any, index: number) => (
                      <tr key={index}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          {item?.type}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {item?.details}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {item?.start}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {item?.end}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {renderDecision(item?.decision)}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          <Link
                            to={`/timeaway/${item.id}`}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            Edit<span className="sr-only">, {item?.type}</span>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* <Pagination
                  totalItems={totalItems}
                  totalPages={totalPages}
                  count={20}
                  page={page}
                  increment={incrementPage}
                  decrement={decrementPage}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const basicInformationPayload = {
    title: 'Basic information',
    attachments: false,
    fields: [
      {
        name: 'Preferred name',
        value: data?.preferred_first_name || data?.first_name,
      },
      {
        name: 'First name',
        value: data?.first_name,
      },
      {
        name: 'Last name',
        value: data?.last_name,
      },
    ],
  };

  const contactInformationPayload = {
    title: 'Contact information',
    attachments: false,
    fields: [
      {
        name: 'Telephone',
        value: data?.work_phone_number_primary,
      },
      {
        name: 'Login email',
        value: data?.email,
      },
    ],
  };

  const employmentInformationPayload = {
    title: 'Employment',
    attachments: false,
    fields: [
      {
        name: 'Role',
        value: data?.role_name,
      },
      {
        name: 'Team',
        value: data?.team,
      },
      {
        name: 'Office',
        value: data?.office_name,
      },
      {
        name: 'Date joined',
        value: data?.date_joined,
      },
    ],
  };

  const accountInformationPayload = {
    title: 'Account',
    fields: [
      {
        name: 'Permissions',
        value: data?.is_admin === true ? 'Admin' : 'Employee',
      },
    ],
  };

  const managementInformationPayload = {
    title: 'Management',
    fields: [
      {
        name: 'Manager',
        id: data?.management?.manager?.id,
        image: data?.management?.manager?.safe_profile_picture_url,
        value: data?.management?.manager?.full_name,
      },
      {
        name: 'Direct reports',
        value: data?.management?.reports,
        mapper: (items: any = []) => items.map(buildUserObject),
      },
    ],
  };

  const buildUserObject = (user: any) => {
    console.log({ user });
    return (
      <a
        key={1}
        href={`/profile/${user.id}`}
        className="p-1"
        title={user.full_name}
      >
        <img
          alt=""
          src={user.safe_profile_picture_url}
          className="h-11 w-11 flex-none rounded-full bg-gray-100"
        />
      </a>
    );
  };

  return (
    <div>
      <div className="bg-white">
        <div className="bg-white">
          <img
            className="h-32 w-full object-cover lg:h-48"
            src={profile?.coverImageUrl}
            alt=""
          />
        </div>
        <div className="mx-auto max-w-5xl bg-white px-4 sm:px-6 lg:px-8">
          <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
            <div className="flex">
              <img
                className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                src={data?.safe_profile_picture_url}
                alt=""
              />
            </div>
            <div className="mt-6 bg-white sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
              <div className="mt-6 min-w-0 flex-1 sm:hidden 2xl:block">
                <h1 className="truncate text-2xl font-bold text-gray-900">
                  {data?.full_name}
                </h1>
              </div>
            </div>
          </div>
          <div className="mt-6 hidden min-w-0 flex-1 bg-white sm:block 2xl:hidden">
            <h1 className="truncate text-2xl font-bold text-gray-900">
              {data?.full_name}
            </h1>
          </div>
        </div>
      </div>

      {/* Tabs */}
      <TabGroup>
        <div className="mb-5 bg-white">
          <div className="border-b border-gray-200">
            <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                <TabList className="mt-1">
                  <Tab>Profile</Tab>
                  <Tab>Holiday Requests</Tab>
                </TabList>
              </nav>
            </div>
          </div>
        </div>
        <TabPanels>
          <TabPanel>
            <ProfileDescription />
          </TabPanel>
          <TabPanel>
            <ProfileHolidayRequest />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  );
}
