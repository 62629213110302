import { useState, useEffect, useReducer } from 'react';
import { QueryClient } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { GlobalReducer } from '../reducer/GlobalReducer';
import axios from 'axios';

export function useAppInitialization() {
  const [loaderActive, setLoaderActive] = useState(false);
  const [token, setToken] = useState<string>('');
  const queryClient = new QueryClient();

  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  const [state, dispatch] = useReducer(GlobalReducer, {
    notification: {
      auth: {
        login: false,
        reset: false,
      },
    },
  });

  // Set up Axios interceptors
  useEffect(() => {
    axios.interceptors.request.use(async (config) => {
      const token = await getAccessTokenSilently();
      config.headers['Authorization'] = `Bearer ${token}`;

      return config;
    });
  }, [getAccessTokenSilently]);

  // Handle authentication and loading states
  useEffect(() => {
    async function checkUser() {
      if (!isAuthenticated && !isLoading) {
        await loginWithRedirect();
        setLoaderActive(true);
      }

      if (isAuthenticated && !isLoading) {
        setLoaderActive(false);
      }
    }

    if (!isAuthenticated && isLoading) {
      setLoaderActive(true);
    }

    checkUser();
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  // Use Promise to handle the asynchronous call
  const getAccessToken = async () => {
    if (isAuthenticated) {
      return await getAccessTokenSilently();
    } else {
      return null;
    }
  };

  // Call the Promise
  const accessTokenPromise = async () => {
    const token = await getAccessToken();
    if (token?.length) {
      setToken(token);
    }
  };

  accessTokenPromise();

  return { loaderActive, queryClient, state, dispatch, token };
}
